var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"doctor-edit-modal","title":"Doctor Edit Modal","centered":"","hide-footer":"","size":"lg","no-close-on-esc":true,"no-close-on-backdrop":true},on:{"hidden":_vm.reset},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h2',{staticClass:"m-0"},[_vm._v("Doctor Edit")])]},proxy:true}])},[_c('validation-observer',{ref:"doctorEditFormValidation"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label-for":"name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('b-form-input',{directives:[{name:"restrict",rawName:"v-restrict",value:(_vm.regex),expression:"regex"}],attrs:{"id":"name","placeholder":"Doctor Name","required":""},on:{"keydown":function($event){return _vm.checkMaxLength($event, 'name', 200)}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('b-form-group',{attrs:{"label-for":"email"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Email "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('b-form-input',{attrs:{"id":"email","type":"email","placeholder":"Email","required":""},on:{"keydown":function($event){return _vm.checkMaxLength($event, 'email', 100)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('b-form-group',{attrs:{"label-for":"mobile"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Mobile "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Mobile","rules":"required|integer|length:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mobile","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Mobile"},model:{value:(_vm.mobile),callback:function ($$v) {_vm.mobile=$$v},expression:"mobile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"password"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Password "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Password","rules":{
            regex:
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d])[\S]{8,}$/,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"password","type":"password","placeholder":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('span',{class:_vm.noteClass(errors),staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.noteText(errors))+" ")])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"intro"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Intro "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('b-form-textarea',{attrs:{"id":"intro","placeholder":"Intro","rows":"3","max-rows":"6"},on:{"keydown":function($event){return _vm.checkMaxLength($event, 'intro', 400)}},model:{value:(_vm.intro),callback:function ($$v) {_vm.intro=$$v},expression:"intro"}})],1),_c('b-form-group',{attrs:{"label-for":"about"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Credentials ")]},proxy:true}])},[_c('quill-editor',{attrs:{"id":"about"},model:{value:(_vm.about),callback:function ($$v) {_vm.about=$$v},expression:"about"}})],1),_c('b-form-group',{attrs:{"label-for":"activities"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Bio ")]},proxy:true}])},[_c('quill-editor',{attrs:{"id":"activities"},model:{value:(_vm.activities),callback:function ($$v) {_vm.activities=$$v},expression:"activities"}})],1),_c('b-form-group',{attrs:{"label-for":"hospital"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Hospital "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Hospital","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","placeholder":"Select Hospital","options":_vm.hospitals,"reduce":function (option) { return option.id; },"autoscroll":"","clearable":true},model:{value:(_vm.hospital),callback:function ($$v) {_vm.hospital=$$v},expression:"hospital"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"department"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Department "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Department","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","placeholder":"Select Department","options":_vm.departments,"reduce":function (option) { return option.id; },"autoscroll":"","clearable":true},model:{value:(_vm.department),callback:function ($$v) {_vm.department=$$v},expression:"department"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"status"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Status "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('b-form-select',{attrs:{"id":"status","options":_vm.options,"required":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),_c('b-form-group',{attrs:{"label-for":"image"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Image "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[(_vm.image)?_c('div',{staticClass:"mb-2"},[_c('img',{staticClass:"img-thumbnail",staticStyle:{"max-width":"15%","margin-bottom":"10px"},attrs:{"src":_vm.image,"alt":"Doctor Image"}})]):_vm._e(),_c('validation-provider',{attrs:{"name":"Image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{staticClass:"form-group",attrs:{"id":"input-group-2","label-for":"input-2","description":"Upload an image in PNG, JPG, or JPEG format.","invalid-feedback":errors[0]}},[_c('b-form-file',{ref:"imageInput",attrs:{"id":"documents","placeholder":"Choose a PNG, JPG, or JPEG file or drop it here...","drop-placeholder":"Drop file here...","accept":".png,.jpg,.jpeg","plain":""},on:{"change":function($event){return _vm.validateFileType($event, 'files')}},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1)]}}])})],1),_c('b-form-group',{staticClass:"mt-2 text-right"},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"submit","variant":"info","pill":""}},[_vm._v(" Update ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }